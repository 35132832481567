import { initLeadForms, setSubmitListeners } from '@aquent/web-lead-form-wp';

initLeadForms(import.meta.env.VITE_FORMS_URL);

// Rewrite the submit buttons from <input> to <button>
document.addEventListener('leadFormRendered', (event) => {
  const submitButton = event.detail.formDiv.querySelector('input[type="submit"]');
  // Create a new button element
  const button = document.createElement('button');
  button.className = 'gform_button a-button is-size-large';
  button.id = submitButton.id;
  button.tabIndex = submitButton.tabIndex;
  button.type = 'submit';

  // Create the span elements
  const span1 = document.createElement('span');
  span1.className = 'a-button__link';

  const span2 = document.createElement('span');
  span2.dataset.marquee = submitButton.value;
  span2.textContent = submitButton.value;

  span1.appendChild(span2);
  button.appendChild(span1);

  // Replace the input element with the button element
  submitButton.parentNode.replaceChild(button, submitButton);

  // Reset the submit listeners
  setSubmitListeners(event.detail.form);
});
